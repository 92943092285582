var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { type: "flex", justify: "end" } },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bgFFF" },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "120px" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "任务ID:" } }, [
                        _vm._v(_vm._s(_vm.pushId)),
                      ]),
                      _c("el-form-item", { attrs: { label: "推送状态:" } }, [
                        _c("span", { staticClass: "colorC" }, [
                          _vm._v(
                            _vm._s(_vm._f("filterStatus")(_vm.pushStatus))
                          ),
                        ]),
                      ]),
                      _vm.imgUrl
                        ? _c("el-form-item", [
                            _c("div", {
                              staticClass: "right-con",
                              style: {
                                backgroundImage: "url(" + _vm.imgUrl + ")",
                              },
                            }),
                          ])
                        : _vm._e(),
                      _c("el-form-item", { attrs: { label: "标题:" } }, [
                        _vm._v(_vm._s(_vm.title)),
                      ]),
                      _c("el-form-item", { attrs: { label: "内容:" } }, [
                        _vm._v(_vm._s(_vm.content)),
                      ]),
                      _c("el-form-item", { attrs: { label: "目标应用:" } }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("targetTerminalFilter")(_vm.targetTerminal)
                          )
                        ),
                      ]),
                      _c("el-form-item", { attrs: { label: "跳转类型:" } }, [
                        _vm._v(_vm._s(_vm.jumpUrl)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "推送时间:" } }, [
                        _vm._v(_vm._s(_vm.pushTime)),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: "推送用户标签:" } },
                        _vm._l(_vm.labelNameList, function (item) {
                          return _c(
                            "el-tag",
                            {
                              key: item,
                              staticStyle: {
                                "margin-right": "10px",
                                "background-color": "rgba(153, 0, 153, 1)",
                                color: "#fff",
                              },
                            },
                            [_vm._v(_vm._s(item))]
                          )
                        }),
                        1
                      ),
                      _c("el-form-item", { attrs: { label: "任务描述:" } }, [
                        _vm._v(_vm._s(_vm.remarks)),
                      ]),
                      _c("el-form-item", { attrs: { label: "创建时间:" } }, [
                        _vm._v(_vm._s(_vm.createTime)),
                      ]),
                      _c("el-form-item", { attrs: { label: "最后操作人:" } }, [
                        _vm._v(_vm._s(_vm.updateUserName)),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: "最后修改时间:" } },
                        [_vm._v(_vm._s(_vm.updateTime))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }